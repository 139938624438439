import React from 'react';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';

function ArticlePhoto() {
    return ( <>
        <BackCard data={InputLinks.backCard.cgPhoto}/>
        <br />
        Famille List
        </> );
}

export default ArticlePhoto